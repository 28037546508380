import React, { useState, useRef } from 'react'
import { ImgApi } from 'api/ImgApi'
import ThreeDCanvas from './canvas/main'
import { InputSelect } from 'components/InputSelect'

let canvas3D = null
const api = new ImgApi()

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
  }

  return array
}

let r = []
let pics = []

function App() {
  const [isStarted] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [topic, setTopic] = useState('')
  const [albumId, setAlbumId] = useState('')
  const [showSlides, setShowSlides] = useState(false)
  const [maxPhotos, setMaxPhotos] = useState(10)

  const show = useRef(showSlides)
  show.showSlides = showSlides

  const loadCanvas = async (s) => {
    if (!s) {
      setIsLoading(false)
      if (canvas3D) {
        canvas3D.destroy()
      }
      return
    }
    setIsLoading(true)
    if (canvas3D) {
      canvas3D.destroy()
    }
    canvas3D = new ThreeDCanvas()
    await canvas3D.main(r)
    setIsLoading(false)

    setTimeout(async () => {
      setIsLoading(true)
      let x = pics.pop()
      if (x) {
        r.push(x)
      }
      if (r.length > maxPhotos) {
        r.shift()
      }
      setTimeout(() => {
        loadCanvas(show.showSlides)
      }, 2000)
    }, 10000)
  }

  const onClick = async (e) => {
    let alreadyActive = showSlides
    setShowSlides(true)
    const p = await api.getWallhavenPics(topic)
    while (pics.length > 0) {
      pics.pop()
    }

    while (r.length > 0) {
      r.pop()
    }
    pics.push(...p)
    shuffle(pics)
    if (pics.length > 0) {
      r.push(pics[0])
      if (!alreadyActive) await loadCanvas(show.showSlides)
    }
  }

  const loadGoogleAlbum = async (e) => {
    let alreadyActive = showSlides
    setShowSlides(true)
    let id = albumId.replace('https://photos.app.goo.gl/', '')
    const p = await api.getGoogleAlbum(id)
    while (pics.length > 0) {
      pics.pop()
    }

    while (r.length > 0) {
      r.pop()
    }
    pics.push(...p)
    shuffle(pics)
    r.push(pics[0])
    if (!alreadyActive) await loadCanvas(show.showSlides)
  }

  return (
    <div className='relative bg-gray-600 w-full h-screen flex flex-col'>
      <div
        className={`z-40 fixed inset-x-0 top-0 hover:opacity-100 ${
          showSlides ? 'opacity-5' : 'opacity-100'
        } w-full h-48 xl:h-20 overflow-hidden bg-gray-800 justify-center transition-all ease-in-out duration-1000`}>
        <div className='flex flex-col xl:flex-row gap-2 px-3 py-4 w-full'>
          <div className='flex flex-row'>
            <InputSelect
              placeholder={'Topic (e.g flowers)'}
              disabled={topic.length <= 0}
              value={topic}
              setValue={setTopic}
              onClick={onClick}
              isStarted={isStarted}
              buttonValue={'Topic'}
            />
          </div>
          <div className='group flex flex-row'>
            <InputSelect
              placeholder={'https://photos.app.goo.gl/xxxx'}
              disabled={albumId < 4}
              value={albumId}
              setValue={setAlbumId}
              onClick={loadGoogleAlbum}
              isStarted={isStarted}
              buttonValue={'Google Album'}
            />
          </div>

          {showSlides && (
            <button
              className='bg-blue-400 px-2 py-2 rounded-md w-36 text-white hover:bg-blue-600 h-18'
              onClick={() => {
                setShowSlides(false)
              }}>
              Stop
            </button>
          )}
          <div className='flex flex-col justify-center items-center pl-10'>
            <label htmlFor='default-range' className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>
              Max Number of Photos
            </label>
            <div>
              <input
                id='default-range'
                type='range'
                min='1'
                max='25'
                value={maxPhotos}
                onChange={(e) => {
                  setMaxPhotos(e.target.value)
                }}
                step='1.0'
                className='w-64 h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700'
              />
              <span className='text-white px-3 text-md '>{maxPhotos}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`absolute z-30 bg-gray-900 h-screen overflow-y-scroll flex flex-col w-full items-center ${
          showSlides ? 'opacity-0' : 'opacity-95'
        }`}>
        <div className='mt-48 w-full flex flex-col items-center gap-3 max-w-5xl'>
          <div className='w-full text-center py-8'>
            <span className='text-white text-xl font-mono '>How to Setup your Google Album</span>
          </div>
          <div className='flex flex-col md:flex-row items-start gap-1 pb-8'>
            <span className='text-white text-md font-mono px-10 w-full md:w-80'>1. Create a share link</span>
            <img src='google_help/1.png' className='rounded-xl'></img>
          </div>
          <div className='flex flex-col md:flex-row items-center gap-1 pb-8'>
            <span className='text-white text-md font-mono px-10 w-full md:w-80'>2. Copy the link in the 'Google Album' input button above</span>
            <img src='google_help/2.png' className='rounded-xl'></img>
          </div>
          <div className='w-full text-center py-8'>
            <span className='text-white text-xl font-mono px-10'>Already Have a Google Album with a public link?</span>
          </div>
          <div className='flex flex-col md:flex-row items-center gap-1 pb-8'>
            <span className='text-white text-md font-mono px-10 w-full md:w-80'>1. Click the link icon</span>
            <img src='google_help/3.png' className='rounded-xl'></img>
          </div>
          <div className='flex flex-col md:flex-row items-center gap-1 pb-8'>
            <span className='text-white text-md font-mono px-10 w-full md:w-80'>
              2. Click 'Copy Link' and paste it into the 'Google Album' input button above
            </span>
            <img src='google_help/4.png' className='rounded-xl'></img>
          </div>
        </div>
      </div>
      <div
        className={`flex flex-row justify-center flex-grow ${isLoading ? 'opacity-0' : 'opacity-100'}  transition-opacity ease-linear duration-1000`}>
        <canvas id='bg' className='bg-gray-600 w-full flex flex-grow'></canvas>
      </div>
    </div>
  )
}

export default App
