import { Group, Sprite, SpriteMaterial } from 'three'
//import config from '../config.json'

import { getRandom, getRandomInt } from './random'

const blackMaterial = new SpriteMaterial({ color: '#000000' })
const whiteMaterial = new SpriteMaterial({ color: '#FDFDFD' })
const offWhiteMaterial = new SpriteMaterial({ color: '#FCFCFC' })

export const blankPicture = () => {
  const material = new SpriteMaterial({
    //map: tex,
    color: 0xffffff,
  })
  let ratio = 1
  let size = 200 //window.innerHeight * 0.5

  const sprite = new Sprite(material)
  sprite.scale.set(size, size / ratio, 1)
  sprite.position.set(0, 0, 0)

  const whiteSprite = new Sprite(whiteMaterial)
  whiteSprite.scale.set(size + size / 20, size / ratio + size / 20, 1)
  whiteSprite.position.set(0, 0, -2)

  const picture = new Group()

  picture.add(sprite)
  picture.add(whiteSprite)

  return picture
}

export const updatePicture = (obj, img) => {
  let ratio = img.image.width / img.image.height
  let size = getRandomInt(200, 250) //window.innerHeight * config.img_screen_multiplier
  let sprite = obj.children[0]
  sprite.material.map = img
  sprite.scale.set(size, size / ratio, 1)

  let white = obj.children[1]
  white.scale.set(size + size / 20, size / ratio + size / 20, 1)
  if (img.image.height > img.image.width) {
    sprite.scale.set(size * ratio, size, 1)
    white.scale.set(size * ratio + size / 20, size + size / 20)
  }

  let initialRotatation = getRandom(-0.5, 0.5)
  sprite.material.rotation = initialRotatation
  white.material.rotation = initialRotatation

  sprite.position.set(0, 0, 0)
  white.position.set(0, 0, -1)

  obj.position.set(0, 0, 120)
}

export const blankBackgroundPicture = () => {
  const material = new SpriteMaterial({
    //alphaMap: tex,
    color: 0xffffff,
  })
  let ratio = 1
  let size = 200 //window.innerHeight * config.img_screen_multiplier

  const sprite = new Sprite(material)
  sprite.scale.set(size, size / ratio, 1)
  let blackMaterialClone = blackMaterial.clone()
  const blackSprite = new Sprite(blackMaterialClone)
  sprite.position.set(0, 0, 0)

  blackSprite.scale.set(size, size / ratio, 1)
  blackSprite.position.set(0, 0, -1)

  const offWhiteMaterialClone = offWhiteMaterial.clone()
  const whiteSprite = new Sprite(offWhiteMaterialClone)

  whiteSprite.scale.set(size + size / 20, size / ratio + size / 20, 1)
  whiteSprite.position.set(0, 0, -2)

  let initialRotatation = getRandom(-0.5, 0.5)
  sprite.material.rotation = initialRotatation
  blackSprite.material.rotation = initialRotatation
  whiteSprite.material.rotation = initialRotatation

  const picture = new Group()

  picture.add(sprite)
  picture.add(blackSprite)
  picture.add(whiteSprite)

  return picture
}

export const updateBackgroundPicture = (obj, img, z, xindex, yindex) => {
  if (!obj || !img) {
    return
  }
  let sprite = obj.children[0]
  sprite.material.alphaMap = img

  let ratio = img.image.width / img.image.height
  let size = getRandomInt(200, 300) //window.innerHeight * config.img_screen_multiplier_back

  sprite.scale.set(size, size / ratio, 1)
  sprite.position.set(0, 0, 0)

  let blackSprite = obj.children[1]
  blackSprite.scale.set(size - 2, size / ratio - 2, 1)
  blackSprite.position.set(0, 0, -1)

  let whiteSprite = obj.children[2]
  whiteSprite.scale.set(size + size / 20, size / ratio + size / 20, 1)
  whiteSprite.position.set(0, 0, -2)

  let d = 1
  if (z % 2 === 0) {
    d = -1
  }

  obj.position.set(getRandomInt(-1 * 150, 150), getRandomInt(-1 * 150, 150), z * d)
  //setPosition(obj, xindex, yindex, z)
}
