import { WebGLRenderer, Clock, Scene, LoadingManager, TextureLoader, PerspectiveCamera, Color, Box3, Vector3, Group } from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import config from './config.json'
import { blankPicture, updatePicture, blankBackgroundPicture, updateBackgroundPicture } from './components/picture'
import { ImgApi } from 'api/ImgApi'

export default class ThreeDCanvas {
  constructor() {
    this.ROWS = config.rows
    this.COLUMNS = config.rows
    // const IMAGES = config.number_of_images
    this.IMAGESTODISPLAY = config.display_images
    // const DIR = config.files_dir
    // const IMG = config.files_name
    // const EXT = config.files_ext
    this.loadManager = new LoadingManager()
    this.loader = new TextureLoader(this.loadManager)
    this.images = []
    this.clock = new Clock()
    this.blanks = []
    this.mainPicture = null
    this.api = new ImgApi()
    this.camera = null
    this.renderer = null
    this.controls = null
    this.scene = null
    this.canvas = document.querySelector('#bg')
    this.allObj = new Group()
  }

  fitCameraToCenteredObject(camera, object, offset, orbitControls) {
    const boundingBox = new Box3()
    boundingBox.setFromObject(object)

    //var middle = new Vector3()
    var size = new Vector3()
    boundingBox.getSize(size)

    // figure out how to fit the box in the view:
    // 1. figure out horizontal FOV (on non-1.0 aspects)
    // 2. figure out distance from the object in X and Y planes
    // 3. select the max distance (to fit both sides in)
    //
    // The reason is as follows:
    //
    // Imagine a bounding box (BB) is centered at (0,0,0).
    // Camera has vertical FOV (camera.fov) and horizontal FOV
    // (camera.fov scaled by aspect, see fovh below)
    //
    // Therefore if you want to put the entire object into the field of view,
    // you have to compute the distance as: z/2 (half of Z size of the BB
    // protruding towards us) plus for both X and Y size of BB you have to
    // figure out the distance created by the appropriate FOV.
    //
    // The FOV is always a triangle:
    //
    //  (size/2)
    // +--------+
    // |       /
    // |      /
    // |     /
    // | F° /
    // |   /
    // |  /
    // | /
    // |/
    //
    // F° is half of respective FOV, so to compute the distance (the length
    // of the straight line) one has to: `size/2 / Math.tan(F)`.
    //
    // FTR, from https://threejs.org/docs/#api/en/cameras/PerspectiveCamera
    // the camera.fov is the vertical FOV.

    const fov = camera.fov * (Math.PI / 180)
    const fovh = 2 * Math.atan(Math.tan(fov / 2) * camera.aspect)
    let dx = size.z / 2 + Math.abs(size.x / 2 / Math.tan(fovh / 2))
    let dy = size.z / 2 + Math.abs(size.y / 2 / Math.tan(fov / 2))
    let cameraZ = Math.max(dx, dy)

    // offset the camera, if desired (to avoid filling the whole canvas)
    if (offset !== undefined && offset !== 0) cameraZ *= offset

    camera.position.set(0, 0, cameraZ)

    // set the far plane of the camera so that it easily encompasses the whole object
    const minZ = boundingBox.min.z
    const cameraToFarEdge = minZ < 0 ? -minZ + cameraZ : cameraZ - minZ

    //camera.far = cameraToFarEdge * 3
    camera.updateProjectionMatrix()

    if (orbitControls !== undefined) {
      // set camera to rotate around the center
      orbitControls.target = new Vector3(0, 0, 0)

      // prevent camera from zooming out far enough to create far plane cutoff
      orbitControls.maxDistance = cameraToFarEdge * 2
    }
  }

  destoryImages() {
    for (let i = 0; i < this.images.length; i++) {
      this.images[i].dispose()
    }
  }

  destroy() {
    if (this.scene) {
      for (let i = 0; i < this.blanks.length; i++) {
        this.scene.remove(this.blanks[i])
      }
      if (this.mainPicture) {
        this.scene.remove(this.mainPicture)
      }
    }

    this.destoryImages()

    if (this.camera) {
      this.camera = null
    }

    if (this.renderer) {
      this.renderer.clear()
      this.renderer.dispose()
    }

    if (this.controls) {
      this.controls.dispose()
    }

    if (this.mainPicture) {
      this.mainPicture = null
    }

    if (this.blanks.length > 0) {
      for (let x = 0; x < this.blanks.length; x++) {
        this.blanks[x] = null
      }
    }

    if (this.scene) {
      this.scene = null
    }
  }

  setCamera() {
    this.camera = new PerspectiveCamera(25, window.innerWidth / window.innerHeight, 10, 10000000)
    this.camera.rotation.set(0, 0, 0)
    this.camera.position.set(0, 0, 3500)

    return this.camera
  }

  setRenderer() {
    this.renderer = new WebGLRenderer({
      canvas: this.canvas,
      antialias: true,
    })

    this.renderer.setPixelRatio(window.devicePixelRatio)
    // this.renderer.setSize(window.innerWidth, window.innerHeight)
    this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight)
    this.camera.aspect = this.canvas.clientWidth / this.canvas.clientHeight
    //   this.camera.updateProjectionMatrix()
    this.renderer.setClearColor(0x0000f)
    // renderer.info

    return this.renderer
  }

  setController() {
    this.controls = new OrbitControls(this.camera, this.renderer.domElement)
    this.controls.enableZoom = true
    this.controls.enablePan = true
    this.controls.minDistance = 600
    this.controls.maxDistance = 50000
    this.controls.enableRotate = true
    this.controls.minAzimuthAngle = -1.3
    this.controls.maxAzimuthAngle = 1.3
    this.controls.minPolarAngle = Math.PI / 3
    this.controls.maxPolarAngle = Math.PI / 1

    this.controls.update()
    return this.controls
  }

  async loadImages(pics) {
    this.destoryImages()
    const p = []

    for (let x = 0; x < pics.length; x++) {
      // images.push(loader.load(`./${DIR}/${IMG}${getRandomInt(0, IMAGES)}.${EXT}`))
      p.push(this.loader.loadAsync(this.api.getImageUrl(pics[x])))
    }

    this.images = await Promise.all(p)
  }

  loadScene() {
    if (this.images.length <= 0) return
    updatePicture(this.mainPicture, this.images[this.images.length - 1])

    for (let x = 0; x < this.blanks.length; x++) {
      updateBackgroundPicture(this.blanks[x], this.images[x], x, x, x)
    }
  }

  loop() {
    this.loadScene()
  }

  async main(pics) {
    await this.loadImages(pics)
    for (let i = 0; i < this.images.length - 1; i++) {
      let b = blankBackgroundPicture()
      this.blanks.push(b)
    }

    this.mainPicture = blankPicture()
    this.loop()
    this.scene = new Scene()
    this.scene.background = new Color('#1A1E35')
    //this.allObj.add(this.mainPicture)
    for (let i = 0; i < this.blanks.length; i++) {
      //this.allObj.add(this.blanks[i])
      this.scene.add(this.blanks[i])
    }
    this.scene.add(this.mainPicture)
    //this.scene.add(this.allObj)
    this.setCamera()
    this.setRenderer()
    this.setController()

    this.fitCameraToCenteredObject(this.camera, this.mainPicture, 1.8, undefined)

    const onWindowResize = () => {
      if (this.renderer && this.camera) {
        this.renderer.setPixelRatio(window.devicePixelRatio)
        this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight)
        this.camera.aspect = this.canvas.clientWidth / this.canvas.clientHeight
        this.camera.updateProjectionMatrix()
      }
    }

    window.addEventListener('resize', onWindowResize)

    const animate = () => {
      if (this.renderer) {
        requestAnimationFrame(animate)
        render()
      }
    }

    const render = () => {
      if (this.renderer && this.camera) {
        const delta = this.clock.getDelta()
        this.controls.update()

        this.camera.position.z -= 10 * delta

        this.renderer.render(this.scene, this.camera)
      }
    }

    const reloadImages = () => {
      // this.loop(camera, scene)

      animate()

      // renderer.autoClear = false
      setTimeout(() => {
        // location.reload()
        // console.log(renderer.info)
        // console.log(scene)
        // //dispose(scene, camera, renderer)
        // loadImages()
      }, config.swap_ms)
    }

    reloadImages()
  }
}
