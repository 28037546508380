import axios from 'axios'

export class ImgApi {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_URL
  }

  async getWallhavenPics(topic) {
    try {
      try {
        const response = await axios.get(`${this.baseUrl}/wallhaven?topic=${topic}`)
        return response.data
      } catch (error) {
        console.log(error)
        return error
      }
    } catch (error) {
      return error
    }
  }

  async getGoogleAlbum(id) {
    try {
      const response = await axios.get(`${this.baseUrl}/googlePhotos?id=${id}`)
      return response.data
    } catch (error) {
      console.log(error)
      return error
    }
  }

  getImageUrl(link) {
    return `${this.baseUrl}/image?targetUrl=${link}`
  }
}
