import { useState } from 'react'

export const InputSelect = ({ placeholder, buttonValue, disabled, value, setValue, onClick, isStarted }) => {
  const [show, setShow] = useState(false)

  return (
    <>
      {show ? (
        <>
          <input
            placeholder={placeholder}
            className='appearance-none py-2 outline-none focus:border-2 focus:border-gray-400 w-full xl:w-64 bg-gray-500 px-2 rounded-l-md ml-2 text-white'
            value={value}
            onChange={(e) => {
              setValue(e.target.value)
            }}></input>
          <button
            className='bg-blue-400 px-2 py-2 rounded-r-md w-48 xl:w-24 text-white hover:bg-blue-600 disabled:bg-gray-700'
            disabled={disabled}
            onClick={onClick}>
            {isStarted ? 'Stop' : 'Load'}
          </button>{' '}
        </>
      ) : (
        <button
          className='bg-blue-500 px-3 py-2 xl:w-48 w-full rounded-md text-white'
          onClick={() => {
            setShow(!show)
          }}>
          {buttonValue}
        </button>
      )}
    </>
  )
}
